import * as React from "react"
import {
  getSectionsId,
  getWorkout,
  getPathWorkout,
  createContentReproduced,
} from "../../../api/content"
import ContentPageLayout from "../../../components/ContentPageLayout"
import { ReactVideo } from "reactjs-media"
import StarIcon from "@material-ui/icons/Star"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import ShareIcon from "@material-ui/icons/Share"
import CastConnectedIcon from "@material-ui/icons/CastConnected"
import config, { firebaseInit } from "../../../config"
import { BiLeftArrowAlt } from "@react-icons/all-files/bi/BiLeftArrowAlt"
import { Link } from "gatsby"
import firebase from "firebase"
import SCButton from "../../../components/buttons/SCButton"
import PremiumButton from "../../../components/buttons/PremiumButton"
import { navigate } from "gatsby-link"
import Spinner from "../../../components/Spinner"
import RatingPopup from "../../../components/modals/RaitingPopup"

const WorkoutContentView = ({ ...props }) => {
  const workoutId = props.params.id
  const [workout, setWorkout] = React.useState()
  const profileUri = config.serverUri
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [isContentPremium, setIsContentPremium] = React.useState(null)
  const [firstClick, setFirstClick] = React.useState(false)
  const [infoSeccion, setInfoSeccion] = React.useState([])
  const [path, setPath] = React.useState(undefined)
  const [timeUse, setTimeUse] = React.useState(0)
  const [runTime, setRunTime] = React.useState(false)
  const [userToken, setUserToken] = React.useState(null)
  const [dontShowAnymore, setDontShowAnymore] = React.useState(false)
  const [ratingTimer, setRatingTimer] = React.useState(null)
  const [popupShown, setPopupShown] = React.useState(false) // Para controlar si el popup ya fue mostrado

  React.useEffect(() => {
    getWorkout(workoutId).then(data => {
      setWorkout(data)
      console.log("data.tipoContenido")
      console.log(data)
      setIsContentPremium(data.tipoContenido)
      getSectionsId().then(response => {
        let flag = false
        if (response && response.length > 0) {
          for (let id of response) {
            getPathWorkout(id, data.id).then(item => {
              if (item) {
                flag = true
                setPath(item)
              }
            })
            if (flag) {
              break
            }
          }
        }
        if (!path) {
          setPath(false)
        }
      })
    })
  }, [props.params.id])

  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true).then(token => {
          setUserToken(token)
        })
        fetch(profileUri + `/users/?email=${user.email}`)
          .then(response => {
            response.json().then(data => {
              setInfoSeccion({ idUser: data[0].id, name: data[0].nombre })
              setUserHasSuscription(data[0].suscription)
            })
          })
          .catch(error => {
            console.log(error)
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        setUserHasSuscription(false)
      }
    })
  }, [])

  const workoutDescription = () => {
    return workout.descripcion
      .split("\n")
      .forEach(workoutDescription => <div>{workoutDescription}</div>)
  }

  const handleVisitVideo = workout => {
    if (!firstClick) {
      const idVideo = workout.id
      const nameVideo = workout.nombre
      const nameCoach = workout.coach.nombre
      const idCoach = workout.coach.id
      const data = {
        id: idVideo,
        nombre: nameVideo,
        idUser: infoSeccion.id,
        nombreUser: infoSeccion.nombre,
      }
      setFirstClick(true)
      window.gtag("event", `Video-${nameVideo}-Web`, data)

      const data2 = {
        id: idVideo,
        nombre: nameVideo,
        idUser: infoSeccion.id,
        nombreUser: infoSeccion.nombre,
        idCoach: idCoach,
        nombreCoach: nameCoach,
      }
      window.gtag("event", `Video-${nameVideo}-Coach-${nameCoach}-Web`, data2)
      console.log(data)
    }
  }

  React.useEffect(() => {
    let intervalId

    if (runTime && timeUse < 120) {
      intervalId = setInterval(() => {
        setTimeUse(prevTime => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [runTime])

  React.useEffect(() => {
    if (workout !== undefined) {
      // console.log(timeUse)
      if (timeUse === 120) {
        saveContentView()
        setRunTime(false)
        // console.log('entro')
      }
    }
  }, [timeUse])

  // Función para iniciar el temporizador
  const startRatingTimer = videoDuration => {
    if (videoDuration > 180) {
      const remainingTime = (videoDuration - 120) * 1000
      const timer = setTimeout(() => {
        setPopupShown(true)
      }, remainingTime)
      setRatingTimer(timer)
    }
  }

  // Función para detener el temporizador
  const stopRatingTimer = () => {
    if (ratingTimer) {
      clearTimeout(ratingTimer)
      setRatingTimer(null)
    }
  }

  // Cambia la lógica de manejo de reproducción del video para iniciar y detener el temporizador
  const handlePlayVideo = workout => {
    const b2bTime = JSON.parse(localStorage.getItem("B2B_PLAY"))
    if (b2bTime === null) {
      const data = {
        start: Date.now(),
        content: workout,
        token: userToken,
      }
      localStorage.setItem("B2B_PLAY", JSON.stringify(data))
      const videoElement = document.querySelector("video") // Selecciona el elemento de video
      const videoDuration = videoElement ? videoElement.duration : 0 // Obtiene la duración total del video
      startRatingTimer(videoDuration) // Iniciar temporizador basado en la duración del video
    } else {
      console.log(b2bTime.start)
    }
  }

  // Función para manejar el evento de cambio de tiempo de reproducción del video
  const handleVideoTimeChange = event => {
    const currentTime = event.target.currentTime
    const videoDuration = event.target.duration // Obtiene la duración total del video

    // Si quedan 2 minutos para acabar el video y el popup no se ha mostrado aún
    if (
      videoDuration > 180 &&
      videoDuration - currentTime <= 120 &&
      !popupShown
    ) {
      setPopupShown(true)
      stopRatingTimer() // Detener el temporizador si el popup se muestra
    }

    // Si el video dura 3 minutos o menos y el video ha terminado
    if (videoDuration <= 180 && currentTime >= videoDuration && !popupShown) {
      setPopupShown(true)
      stopRatingTimer() // Detener el temporizador si el popup se muestra
    }
  }

  const visitVideoCoacha = data => {
    window.gtag("event", "videos-vistos-coach-web", data)
  }

  const saveContentView = () => {
    const data = {
      contenidoId: "" + workout.id,
      contenido: "" + workout.nombre,
    }

    createContentReproduced(userToken, data).then(res => {
      console.log(res)
    })
  }
  const closeRatingPopup = () => {
    setDontShowAnymore(true)
    setPopupShown(false)
  }

  return (
    <ContentPageLayout>
      {infoSeccion.idUser && popupShown && !dontShowAnymore && (
        <RatingPopup
          closeModal={closeRatingPopup}
          userId={infoSeccion.idUser}
          classId={workout.id}
          coachId={workout.coach.id}
          portada={workout.portada?.url}
        />
      )}

      {/* {console.log(workout)} */}
      {workout ? (
        <div className="pb-20">
          <div className="w-11/12 my-6 mx-8 flex items-center">
            <div onClick={() => window.history.go(-1)}>
              <BiLeftArrowAlt className="text-3xl cursor-pointer" />{" "}
            </div>
            <span className="pl-4">
              {path ? (
                <>
                  {path.seccion} / {path.tipo} / {workout.nombre}
                </>
              ) : (
                <>Entrenamientos / Contenido / {workout.nombre}</>
              )}
            </span>
          </div>
          <div className="p-8">
            <h2 className="text-sc-primary">{workout.nombre}</h2>
          </div>

          <div className="flex flex-col md:flex-row md:p-8 justify-around">
            {isContentPremium && userHasSuscription != null ? (
              (isContentPremium == "premium" &&
                userHasSuscription == "premium") ||
              isContentPremium == "free" ? (
                <>
                  <div>
                    <div>
                      <ReactVideo
                        src={workout.mediaSource}
                        poster={
                          workout.portada && workout.portada.url
                            ? `${workout.portada.url}`
                            : "https://images.unsplash.com/photo-1445384763658-0400939829cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                        }
                        type="video/mp4"
                        className="w-full"
                        primaryColor="#00CEA9"
                        onPlay={() => {
                          setRunTime(true)
                          handlePlayVideo(workout)
                          handleVisitVideo(workout)
                        }}
                        onPause={() => {
                          setRunTime(false)
                          stopRatingTimer() // Detener el temporizador cuando el video se detiene o se pausa
                        }}
                        onTimeUpdate={handleVideoTimeChange} // Usar el nuevo manejador de evento modificado
                      />
                    </div>
                    <div
                      style={{ display: "none" }}
                      className="flex justify-between px-6 shadow py-4"
                    >
                      <div className="text-sc-black" style={{ fontSize: 16 }}>
                        <span>
                          <StarIcon color="#0C0C0C"></StarIcon> {workout.rating}
                        </span>
                      </div>
                      <div className="text-sc-black" style={{ fontSize: 16 }}>
                        <span>
                          <CloudDownloadIcon color="#0C0C0C"></CloudDownloadIcon>
                        </span>
                      </div>
                      <div className="text-sc-black" style={{ fontSize: 16 }}>
                        <span>
                          <ShareIcon color="#0C0C0C"></ShareIcon>
                        </span>
                      </div>
                      <div className="text-sc-black" style={{ fontSize: 16 }}>
                        <span>
                          <CastConnectedIcon color="#0C0C0C"></CastConnectedIcon>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-8">
                    {workout.coach ? (
                      <div className="mt-8">
                        <div className="flex items-center align-center">
                          {workout.coach.fotoPerfil ? (
                            <div
                              className="w-20 h-20 md:w-24 md:h-24"
                              style={{ marginRight: 15 }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: 50,
                                }}
                                src={
                                  workout.coach.fotoPerfil.url.includes("https")
                                    ? `${workout.coach.fotoPerfil.url}`
                                    : `${config.serverUri}${workout.coach.fotoPerfil.url}`
                                }
                              ></img>
                            </div>
                          ) : null}
                          <div className="text-sc-body">
                            <span style={{ fontSize: 32 }}>
                              {workout.coach.nombre}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="mt-8">
                      <div
                        className="flex justify-between mt-8"
                        style={{ maxWidth: 350 }}
                      >
                        <div className="text-center">
                          {workout.numeroEjercicios ? (
                            <>
                              <p style={{ fontWeight: "bold" }}>Ejercicios</p>
                              <p>{workout.numeroEjercicios}</p>
                            </>
                          ) : null}
                        </div>

                        <div className="text-center">
                          {workout.numeroSeries ? (
                            <>
                              <p style={{ fontWeight: "bold" }}>Series</p>
                              <p>{workout.numeroSeries}</p>
                            </>
                          ) : null}
                        </div>
                        <div className="text-center">
                          {workout.nivel ? (
                            <>
                              <p style={{ fontWeight: "bold" }}>Dificultad</p>
                              <p>{workout.nivel}</p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {workout.instrucciones ? (
                      <>
                        <div style={{ fontWeight: "bold" }}>Objetivo</div>
                        <div>{workout.instrucciones}</div>
                      </>
                    ) : null}

                    {/* {workout && workout.descripcion ? (
                      <>
                        <div style={{ fontWeight: "bold" }}>Instrucciones</div>
                        {workoutDescription()}
                      </>
                    ) : null} */}
                    <br />
                    {workout && workout.materialRequerido ? (
                      <>
                        <div style={{ fontWeight: "bold" }}>Implementos</div>
                        <div>{workout.materialRequerido}</div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="w-full  flex flex-col items-center justify-center p-10">
                  <p className="text-3xl">
                    Se necesita ser usuario Shifter Premium para poder
                    visualizar este contenido
                  </p>
                  <PremiumButton
                    onClick={() => navigate("/settings/status")}
                  >
                    Hazte premium
                  </PremiumButton>
                </div>
              )
            ) : (
              <div className="w-auto mx-auto">
                <Spinner message="Cargando..." />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </ContentPageLayout>
  )
}

export default WorkoutContentView
